import React, { Component } from 'react';
import ReactDOM from "react-dom";
import axios from 'axios';
import { Form } from '@flipbyte/formik-json';
import CustomAnnotation from '../components/CustomAnnotation';
import { data } from 'jquery';

class FormikJsonForm extends Component {

    constructor(props) {
        super(props)
        this.state = {
            title: "Appointment Questionnaire",
            proxyUrl: "",
            bearer: "",
            basic: "YXBpOkZhdm91cml0ZUNvdW50cnlJc0F6ZXJiYWlqYW4=",
            url: {
              PatientFormID: null,
              ServerID: null,
              FileNameID: null
            },
            sent: false,
            logo: '',
            formFields: null,
            canvasRef: React.createRef(),
            drawing: null,
            ref: React.createRef(),
            schema: {
            
            },
            initial: { 
              attributes: {
                // 'recordId_19240': 'Yes'
              }
            }
        }
    }

    componentDidMount() {

        let params = (new URL(document.location)).searchParams
        let PatientFormID =  params.get("PatientFormID")
        let ServerID =  params.get("ServerID").split('~').join('.')
        let FileNameID =  params.get("FileNameID")

        // don't continue if something is missing
        if(!PatientFormID || !ServerID || !FileNameID) {
          alert("Incorrect URL")
          return false;
        }

        let proxyUrl = "https://" + ServerID + "/fmi/data/v1/databases/" + FileNameID

        this.setState({ 
          proxyUrl: proxyUrl,
          url: {
            PatientFormID: PatientFormID,
            ServerID: ServerID,
            FileNameID: FileNameID
          }
        }, function(){

          axios.post(proxyUrl + '/sessions', {}, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Basic ' + this.state.basic 
              } 
          })
          .then(response => {
            this.setState({bearer: response.data.response.token}, function(){
              this.loadFormDataEcq3()
              this.loadLogo()
            })
   
          }).catch(error => {
              console.log("--- Error reading form configuration ---")
              console.log(error)
          });
        })

    }

    loadData(formData) {
  
      let data = null
      let formFields = formData.response.data
      let initial = {
        attributes: {

        }
      }

      let schema = {
        "id": "my-new-form",
        "step": 1,
        "steps": 4,
        "label": "Quote Form (1/4)",
        "type": "container",
        "endpoint": "CreateCaseJSONPeregrine",
        "renderer": "form",
        "elements": {
          // dynmic part to be filled next from formFields
        }
      };

      let title = null;

      for (let i=0; i<formFields.length; i++) {
        data = null
        let formField = formFields[i]
        let isMandatory = (formField.fieldData.Mandatory=="1")
        let questionType = formField.fieldData.QuestionType;

        if(typeof (formFields[i].fieldData['Form|PatientFormQuestion::Form']) === 'string' && !title ) {
          title = formFields[i].fieldData['Form|PatientFormQuestion::Form']
        }

        console.log("questionType=" + questionType)

        switch (questionType) {

          case 'Annotation':
            data = this.updateSchemaAnnotation(schema, i, formField, isMandatory, initial)
            schema = data[0]
            initial = data[1]
            break

          case 'Tick Box':
          case 'Left Right Tick Box':
            data = this.updateSchemaTickBox(schema, i, formField, isMandatory, initial)
            schema = data[0]
            initial = data[1]
            break

          case 'Drop Down':
            data = this.updateSchemaDropDown(schema, i, formField, isMandatory, initial)
            schema = data[0]
            initial = data[1]
            break

          case 'Information':
          case 'Consent':
          case 'Information/Consent':
              data = this.updateSchemaLabel(schema, i, formField, isMandatory, initial)
              schema = data[0]
              initial = data[1]
              break
  
  
          default:
            data = this.updateSchemaText(schema, i, formField, isMandatory, initial)
            schema = data[0]
            initial = data[1]

        } 

      }

      this.setState({ formFields: formFields, title: title, schema: schema, initial: initial }, () => {});

    }

    updateSchemaAnnotation(schema, i, formField, isMandatory, values) {

      schema.elements['key_' + i] = {
        "name": "attributes.recordId_" + formField.recordId,
        "label": formField.fieldData.Question + (isMandatory ? '*' : ''),
        "type": "field",
        "renderer": CustomAnnotation,
        "fieldType": "text",
        "image": formField.fieldData.Image,
        "width":  formField.fieldData.ImageWidth,
        "height":  formField.fieldData.ImageHeight,
        "attributes": {
          "validation": isMandatory ? 'required' : '',
          "tabIndex": i
        }

      }
      values.attributes['recordId_' + formField.recordId] = ''
      return [schema, values]

    }

    updateSchemaText(schema, i, formField, isMandatory, values) {

      let label = formField.fieldData.Question
      let element_class = (formField.fieldData.QuestionType==='Left Right Free Text') ? 'form-group text-onupd float-left' : 'form-group'

      schema.elements['key_' + i] = {
        "name": "attributes.recordId_" + formField.recordId,
        "label": label + (isMandatory ? '*' : ''),
        "type": "field",
        "renderer": "text",
        "htmlClass": element_class,
        "fieldType": "text",
        "attributes": {
          "validation": isMandatory ? 'required' : '',
          "tabIndex": i
        }
      }

      values.attributes['recordId_' + formField.recordId] = formField.fieldData.Response

      return [schema, values]
    }

    updateSchemaLabel(schema, i, formField, isMandatory, values) {

      let label = formField.fieldData.Question.replace(/\r/g, '\n')
      let element_class = 'form-group'

      schema.elements['key_' + i] = {
        "name": "attributes.recordId_" + formField.recordId,
        "label": label,
        "type": "field",
        "renderer": "inner-text",
        "htmlClass": element_class + " white-space-pre-wrap",
      }

      values.attributes['recordId_' + formField.recordId] = formField.fieldData.Response

      return [schema, values]
    }

    updateSchemaDropDown(schema, i, formField, isMandatory, values) {

      schema.elements['key_' + i] = {
        "name": "attributes.recordId_" + formField.recordId,
        "label": formField.fieldData.Question + (isMandatory ? '*' : ''),
        "type": "field",
        "renderer": "react-select",
        "fieldType": "text",
        "attributes": {
          "validation": isMandatory ? 'required' : '',
          "tabIndex": i
        },
        options: {
        }
      }

      for (let j=0; j<formField.portalData['FormResponse|PatientFormQuestion'].length; j++) {
        let dropDownId = formField.portalData['FormResponse|PatientFormQuestion'][j].recordId;
        let dropDownOption = formField.portalData['FormResponse|PatientFormQuestion'][j]['FormResponse|PatientFormQuestion::Option'];
        schema.elements['key_' + i]['options'][dropDownId] = dropDownOption
      }

      values.attributes['recordId_' + formField.recordId] = formField.fieldData.Response

      return [schema, values]
    }

    updateSchemaTickBox(schema, i, formField, isMandatory, values) {

      let question_type = formField.fieldData.QuestionType
      let answers = formField.fieldData.Response.split('\n')
      let options = []
      values.attributes['recordId_' + formField.recordId] = []

      for(let j=0; j<formField['portalData']['FormResponse|PatientFormQuestion'].length; j++) {
        let entry = formField['portalData']['FormResponse|PatientFormQuestion'][j]
        let lab = entry['FormResponse|PatientFormQuestion::Option']
        options.push({
          'value': entry['recordId'],
          'label': lab,
        })
        values.attributes['recordId_' + formField.recordId].push(answers.includes(lab) ? true : false)

      }

      let element_class = (question_type==='Left Right Tick Box') ? 'form-group w-50 float-left' : 'form-group'

      schema.elements['key_' + i] = {
          "name": "attributes.recordId_" + formField.recordId,
          "htmlClass": element_class,
          label: formField['fieldData']['Question'] ,
          type: "field",
          renderer: "checkbox",
          options: options
      }

      return [schema, values]
    }

    loadFormDataEcq3(ID_PatientForm) {
      let that = this

      let payload = { "query": [ { 
        "PatientForm|PatientFormQuestion::id": this.state.url.PatientFormID, 
        "Active": "1" 
      } ], "sort": [ { "fieldName": "OrderHeader", "sortOrder": "ascend" } ] }

      let url = this.state.proxyUrl + "/layouts/patientFormQuestion_api/_find"
        axios.post(url, payload, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.state.bearer
            } 
        })
        .then(async res => {

          // load image sizes before cropper is initialized
          let formFields = res.data.response.data
          for (let i=0; i<formFields.length; i++) {
            let qt = formFields[i].fieldData.QuestionType
            if(qt==='Annotation') {
              let img = await this.getMeta( formFields[i].fieldData.Image );
              formFields[i].fieldData.ImageWidth = img.width
              formFields[i].fieldData.ImageHeight = img.height
            }
          }
          that.loadData(res.data)

        });
    }

    getMeta(url) {
      return new Promise((resolve, reject) => {
          let img = new Image();
          img.onload = () => resolve(img);
          img.onerror = () => reject();
          img.src = url;
      });
    }
  

    loadLogo() {
      // https://{{ServerID}}/fmi/data/v1/databases/{{Database}}/layouts/logo/_find
      let url = "https://" + this.state.url.ServerID + "/fmi/data/v1/databases/" + this.state.url.FileNameID + "/layouts/logo/_find"
      
      let payload = { "query": [ { "ID_Setting": "1" } ], "sort": [ { "fieldName": "ID_Setting", "sortOrder": "ascend" } ] }
      
      axios.post(url, payload, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.state.bearer
          } 
      }).then(res => {
        if(res.data.messages[0].message==='OK') {
          this.setState({logo: res.data.response.data[0].fieldData.Logo});
        }
        return res;
      });

    }
    
    handleExport() {
      // const base64 = this.state.canvasRef.current.canvasContainer.childNodes[1].toDataURL();
      // this.setState({drawing: base64})
      // setDrawing(base64);
    }

    dataURLtoFile(dataurl, filename) {
 
      var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), 
          n = bstr.length, 
          u8arr = new Uint8Array(n);
          
      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }
      
      return new File([u8arr], filename, {type:mime});
    }  

    render() {
        const { ref, sent, logo, title } = this.state;
        return (
            <div>
              <div className={sent ? 'dynamicForm sent' : 'dynamicForm unsent'}>

                <img alt="Logo" src={logo} width='300' />

                <h1 className="heading sent">Thank you for your submission</h1>

                <div className='questionnaire'>
                  <h1 className="heading">{title ?? 'Appointment Questionnaire'}</h1>

                  <Form schema={this.state.schema}
                  ref={ref} 
                    initialValues={ this.state.initial  }
                    enableReinitialize={true} 
                    onUpdate={(data, formik) => {
                    }}
                    onSubmit={(data, formik) => { 

                    let formFields = this.state.formFields
                    let total = formFields.length
                    let sent = 0

                      if(typeof data.attributes == 'undefined') {
                        console.log('early exit')
                        return false;
                      }

                      // "https://{$ServerID}.e-clinic.uk.com/fmi/data/v1/databases/{$FileNameID}/layouts/patientFormQuestion_api/records/";
                      let url = "https://" + this.state.url.ServerID + "/fmi/data/v1/databases/" + this.state.url.FileNameID + "/layouts/patientFormQuestion_api/records"

                      for (const [key, value] of Object.entries(data.attributes)) {

                        let recordId = key.replace('recordId_', '')

                        for(let j=0; j<formFields.length; j++) {

                          if(formFields[j].recordId===recordId) {

                            let v = value

                            if(typeof v === 'string' && v.substring(0,11)==="data:image/") {

                              let url_img = "https://" + this.state.url.ServerID + "/fmi/data/v1/databases/" + this.state.url.FileNameID + "/layouts/patientFormQuestion_api/records/" + recordId + "/containers/Image/1"

                              let formData = new FormData();
                              let imagefile = this.dataURLtoFile(v, 'image.png');
                              formData.append("upload", imagefile);                            

                              axios.post(url_img, formData, {
                                headers: {
                                  'Content-Type': 'application/x-www-form-urlencoded',
                                  'Authorization': 'Bearer ' + this.state.bearer
                                  } 
                              }).then(res => {
                                sent ++
                                if(sent===total) {
                                  this.setState({sent: true})
                                }
                                  return res;
                              });

                            } else {
                            if(Array.isArray(v)) {
                              let new_v = [];
                              for(let k=0; k<v.length; k++) {
                                if(v[k]===true) {
                                  new_v.push(formFields[j].portalData['FormResponse|PatientFormQuestion'][k]['FormResponse|PatientFormQuestion::Option'])
                                }
                              }
                              v = new_v.join('\n');
                            }
                            
                            let payload = {
                              "fieldData": {
                                  // "PatientForm|PatientFormQuestion::id": formFields[j].fieldData.ID_PatientForm,
                                  "QuestionType": formFields[j].fieldData.QuestionType,
                                  "Question": formFields[j].fieldData.Question,
                                  "Response": v,
                                  "Response_Details": v
                                }                    
                            }

                            axios.patch(url + '/' +recordId, payload, {
                              headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + this.state.bearer
                                } 
                            }).then(res => {
                              sent ++
                              if(sent===total) {
                                this.setState({sent: true})
                              }
                              return res;
                            });
                      
                            break
                          }



                          } // if

                        } // for

                      }


                  }}
                  ></Form>

                  <button className={"btn btn-success btn-block"} onClick={() => { this.handleExport(); ref.current.submitForm() }}>Save</button>
              </div>
              </div>

              <br />
            </div>
        )
    }

}

export default FormikJsonForm;