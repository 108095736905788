import React from 'react';
import './App.css';
import { Route, BrowserRouter as Router} from "react-router-dom";

import HomeDynamic from './Pages/HomeDynamic';

function App() {

  // let history = useHistory();
  return (
    <Router>
    <div className="App">
    </div>

    <Route path="/" exact component={HomeDynamic} />

    </Router>
  );
}

export default App;
