import React from 'react';
// import { Link } from "react-router-dom";
import FormikJsonForm from '../components/FormikJsonForm';


export default function Home() {
    return (
        <div>
            <div className="maincontainer w-container">
                <div className="maindiv">
                    <FormikJsonForm></FormikJsonForm>
                </div>
            </div>
        </div>
    );
}