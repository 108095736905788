import React from 'react';
import CanvasDraw from "react-canvas-draw";

const CustomAnnotation = ({ config, formik, value = '', error }) => {

    let {
        image,
        width=520,
        height=314,
        canvasRef = React.createRef(),
    } = config;

    const undo = () => {
        canvasRef.current.undo()
    }

    const handleOnchange = () => {

        const width = canvasRef.current.props.canvasWidth;
        const height = canvasRef.current.props.canvasHeight;
        const background = canvasRef.current.canvasContainer.children[0]; 
        const drawing = canvasRef.current.canvasContainer.children[1]; 
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;

        // composite now
        canvas.getContext('2d').drawImage(background, 0, 0);
        canvas.getContext('2d').globalAlpha = 1.0; 
        canvas.getContext('2d').drawImage(drawing, 0, 0);

        const dataUri = canvas.toDataURL('image/jpeg', 1.0);
        const data = dataUri.split(',')[1];

        const bytes = window.atob(data);
        const buf = new ArrayBuffer(bytes.length);
        const arr = new Uint8Array(buf);

        for (let i = 0; i < bytes.length; i++) {
            arr[i] = bytes.charCodeAt(i);
        }

        formik.setFieldValue(config.name, dataUri )
    };

    return (
        <React.Fragment>

            <div className='row annotationRow'>
                <div className='col-12'>
                    <CanvasDraw imgSrc={image} canvasHeight={height} canvasWidth={width} onChange={handleOnchange} ref={canvasRef} brushRadius={2} lazyRadius={5} brushColor={'#f00'} />
                    <a href="javascript:void(0)" style={{top: '20px', left: '20px', position: 'absolute'}} className='badge badge-dark' onClick={undo}>Undo</a>
                </div>
              </div>

        </React.Fragment>
    );
}

export default CustomAnnotation;